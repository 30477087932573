import {RiPagesLine} from 'react-icons/ri';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {ALT_TEXT, imageWeb} from '@/sanity/schemas/primitives/image-web';
import {layout} from '@/sanity/schemas/primitives/layout';
import {link} from '@/sanity/schemas/primitives/link';

export const linkListSelection = () =>
	defineField({
		name: 'linkListSelection',
		title: 'Lenkeliste, utvalg',
		type: 'object',
		icon: RiPagesLine,
		fields: [heading(), tagline(), links(), layout()],
		preview: {
			select: {
				title: 'heading',
			},
			prepare: (select) => ({
				title: select.title ?? 'Mangler overskrift...',
				subtitle: 'Lenkeliste, utvalg',
			}),
		},
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
	});

const heading = () =>
	defineField({
		name: 'heading',
		title: 'Overskrift',
		type: 'string',
		group: 'content',
	});

const tagline = () =>
	defineField({
		name: 'tagline',
		title: 'Undertittel',
		type: 'string',
		group: 'content',
	});

const links = () =>
	defineField({
		name: 'links',
		title: 'Lenker med bilde',
		type: 'array',
		group: 'content',
		validation: (Rule: Rule) => Rule.required(),
		of: [linkCard()],
	});

const linkCard = () =>
	defineField({
		name: 'linkCard',
		title: 'Lenkekort',
		type: 'object',
		group: 'content',
		validation: (Rule: Rule) => Rule.required(),
		fields: [title(), image(), ...link().fields],
	});

const title = () =>
	defineField({
		name: 'title',
		title: 'Tittel',
		type: 'string',
		validation: (Rule: Rule) => Rule.required(),
	});

const image = () =>
	defineField({
		...imageWeb({fields: [ALT_TEXT]}),
		name: 'image',
		title: 'Bilde',
		description: 'Et bilde som representerer lenken. Dette bildet brukes i sidelistene.',
		validation: (Rule: Rule) => Rule.required(),
	});

export type LinkListSelectionSchema = Output<typeof linkListSelection> & {
	_type: 'linkListSelection';
};
