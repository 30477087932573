import {groq} from 'next-sanity';
import type {Reference, Rule} from 'sanity';
import {defineField} from 'sanity-typed-schema';

export const LINKABLE_DOCUMENT_TYPES = ['page', 'frontpage'] as const;

export type LinkableDocumentTypes = (typeof LINKABLE_DOCUMENT_TYPES)[number];

export function isLinkableDocumentType(type: string) {
	return LINKABLE_DOCUMENT_TYPES.includes(type as LinkableDocumentTypes);
}

export const link = () => {
	return defineField({
		title: 'Lenke',
		name: 'link',
		type: 'object',
		fields: [type(), reference(), href(), targetBlank()],
		preview: {
			select: {
				title: 'reference.title',
				type: 'type',
			},
			prepare: (select) => ({
				title: select.title,
				subtitle: `${select.type === 'internal' ? 'Intern' : 'Ekstern'} lenke`,
			}),
		},
		validation: (Rule: Rule) => Rule.custom(validateLink),
	});
};

type LinkData = {
	type: 'internal' | 'external';
	reference?: Reference;
	href?: string;
};

export const validateLink = (link?: LinkData) => {
	const {type, reference, href} = link ?? {};
	if (type === 'internal' && !reference) {
		return 'Mangler intern lenke';
	}
	if (type === 'external' && !href) {
		return 'Mangler URL';
	}
	// Check if the URL is a valid URL that starts with http:// or https:// or mailto: or tel:
	if (type === 'external' && href && !href.match(/^(https?:\/\/|mailto:|tel:)/)) {
		return 'Ugyldig URL';
	}

	return true;
};

const type = () =>
	defineField({
		name: 'type',
		title: 'Type',
		description:
			'Lenketypen bestemmer om man ønsker lenke til innhold på dette nettstedet (intern) eller en annen nettside ute på internett (ekstern).',
		type: 'string',
		initialValue: 'internal',
		options: {
			list: [
				{title: 'Intern', value: 'internal'},
				{title: 'Ekstern', value: 'external'},
			],
		},
		validation: (Rule: Rule) => Rule.required(),
	});

const linkableDocumentTypes: {type: string}[] = LINKABLE_DOCUMENT_TYPES.map((documentType) => ({
	type: documentType,
}));

const reference = () =>
	defineField({
		name: 'reference',
		title: 'Intern lenke',
		description: 'Lenke til en side eller annet innhold på dette nettstedet.',
		type: 'reference',
		to: linkableDocumentTypes,
		hidden: ({parent}) => parent?.type !== 'internal',
	});

const href = () =>
	defineField({
		name: 'href',
		title: 'URL',
		description: 'URL til en ekstern nettside eller en mailto- eller tel-lenke.',
		type: 'url',
		validation: (Rule: Rule) =>
			Rule.uri({
				allowRelative: true,
				scheme: ['https', 'http', 'mailto', 'tel'],
			}),
		hidden: ({parent}) => parent?.type !== 'external',
	});

const targetBlank = () =>
	defineField({
		name: 'targetBlank',
		title: 'Åpne i nytt vindu',
		type: 'boolean',
		initialValue: false,
		description: 'Åpne lenken i et nytt nettleservindu',
		validation: (Rule: Rule) => Rule.required(),
	});

export const LINK_FRAGMENT = groq`
	type == "internal" => {
		...,
		reference-> {
			title,
			_type,
			"slug": slug.current,
		}
	},
	type == "external" => {
		...,
	}
`;

export type LinkFragment = {
	_type: 'link';
	href?: string;
	type?: 'internal' | 'external';
	targetBlank?: boolean;
	reference?: {
		title?: string;
		_type: LinkableDocumentTypes;
		slug?: string;
	};
};
